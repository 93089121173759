var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},on:{"input":_vm.get_lists},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.big_btn)?_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),Object.assign({}, dialog)),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]),_vm._v(" "+_vm._s(_vm.activator_name)+" ")],1):_vm._e(),(!_vm.big_btn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(!_vm.big_btn)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"small":""}},'v-icon',attrs,false),Object.assign({}, dialog,tooltip)),[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]):_vm._e()]}}],null,true)},[(!_vm.big_btn)?_c('span',[_vm._v("Edit")]):_vm._e()]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"vform",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handle_submit)}}},[_c('v-card',{staticClass:"light-blue lighten-3"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.form_title))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"personnel_no","name":"Personnel No","rules":"objectNotEmpty:personnel_no|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Select a personnel*","hint":"Search by personnel no or name","items":_vm.personnel_list,"item-text":function (item){ return item.personnel_no + ' | ' + item.name; },"item-value":"personnel_no","required":"","autocomplete":"nope","loading":_vm.personnel_loading},on:{"change":_vm.fill_inputs},model:{value:(_vm.manager.personnel.personnel_no),callback:function ($$v) {_vm.$set(_vm.manager.personnel, "personnel_no", $$v)},expression:"manager.personnel.personnel_no"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"name","name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name*","hint":"Personnel Name","required":"","autocomplete":"nope","maxlength":"256","counter":"","readonly":""},model:{value:(_vm.manager.personnel.name),callback:function ($$v) {_vm.$set(_vm.manager.personnel, "name", $$v)},expression:"manager.personnel.name"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"father_name","name":"Father's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Father's Name*","hint":"Father's Name","required":"","autocomplete":"nope","maxlength":"256","counter":"","readonly":""},model:{value:(_vm.manager.personnel.father_name),callback:function ($$v) {_vm.$set(_vm.manager.personnel, "father_name", $$v)},expression:"manager.personnel.father_name"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"mobile_no","name":"Mobile No","rules":"required|min:11|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mobile No*","hint":"Mobile no","required":"","autocomplete":"nope","maxlength":"15","counter":"","readonly":""},model:{value:(_vm.manager.personnel.mobile_no),callback:function ($$v) {_vm.$set(_vm.manager.personnel, "mobile_no", $$v)},expression:"manager.personnel.mobile_no"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email*","hint":"Personnel Email","required":"","autocomplete":"nope","maxlength":"256","counter":"","type":"email","readonly":""},model:{value:(_vm.manager.personnel.email),callback:function ($$v) {_vm.$set(_vm.manager.personnel, "email", $$v)},expression:"manager.personnel.email"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"branch","name":"Branch","rules":"objectNotEmpty:branch|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Select a Branch*","hint":"Search by branch code or name","items":_vm.branch_list,"item-text":function (item){ return item.code + ' | ' + item.name; },"item-value":"code","required":"","autocomplete":"nope","loading":_vm.branch_loading,"disabled":_vm.disable_branch},on:{"change":_vm.fill_inputs},model:{value:(_vm.manager.branch.code),callback:function ($$v) {_vm.$set(_vm.manager.branch, "code", $$v)},expression:"manager.branch.code"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.handleClose}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","loading":_vm.submit_loading}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }