<template>
  <v-dialog
      max-width="600px"
      v-model="dialog"
      @input="get_lists"
  >


    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">Edit</span>
      </v-tooltip>
    </template>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="personnel_no" name="Personnel No"
                                    rules="objectNotEmpty:personnel_no|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Select a personnel*"
                      hint="Search by personnel no or name"
                      :items="personnel_list"
                      :item-text="(item)=>item.personnel_no + ' | ' + item.name"
                      item-value="personnel_no"
                      v-model="manager.personnel.personnel_no"
                      required
                      autocomplete="nope"
                      @change="fill_inputs"
                      :loading="personnel_loading"
                  >

                  </v-autocomplete>

                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="name" name="Name" rules="required" v-slot="{ errors }">
                  <v-text-field
                      label="Name*"
                      hint="Personnel Name"
                      required
                      autocomplete="nope"
                      v-model="manager.personnel.name"
                      maxlength="256"
                      counter
                      readonly
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="father_name" name="Father's Name" rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Father's Name*"
                      hint="Father's Name"
                      required
                      autocomplete="nope"
                      v-model="manager.personnel.father_name"
                      maxlength="256"
                      counter
                      readonly
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="mobile_no" name="Mobile No" rules="required|min:11|max:15"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Mobile No*"
                      hint="Mobile no"
                      required
                      autocomplete="nope"
                      v-model="manager.personnel.mobile_no"
                      maxlength="15"
                      counter
                      readonly
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="email" name="Email" rules="required|email" v-slot="{ errors }">
                  <v-text-field
                      label="Email*"
                      hint="Personnel Email"
                      required
                      autocomplete="nope"
                      v-model="manager.personnel.email"
                      maxlength="256"
                      counter
                      type="email"
                      readonly
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="branch" name="Branch"
                                    rules="objectNotEmpty:branch|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Select a Branch*"
                      hint="Search by branch code or name"
                      :items="branch_list"
                      :item-text="(item)=>item.code + ' | ' + item.name"
                      item-value="code"
                      v-model="manager.branch.code"
                      required
                      autocomplete="nope"
                      :loading="branch_loading"
                      @change="fill_inputs"
                      :disabled="disable_branch"
                  >
                  </v-autocomplete>

                  <!--                  <v-select label="Select a Branch*" v-model="personnel.branch.code"-->
                  <!--                            hint="The zone where this branch resides"-->
                  <!--                            :items="branch_list" item-text="code" clearable-->
                  <!--                            item-value="code" item-color="primary" :loading="branch_loading">-->

                  <!--                  </v-select>-->
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="handleClose"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"

export default {
  name: "add_edit_form",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    disable_branch() {
      return this.action === 'edit'
    }
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    manager: {
      personnel: {
        id: null,
        personnel_no: '',
        name: '',
        father_name: '',
        email: '',
        mobile_no: '',
      },
      branch: {
        code: '',
        id: '',
        name: ''
      }
    },
    personnel_list: [],
    branch_list: [],
    personnel_loading: false,
    branch_loading: false,
  }),
  methods: {
    async add_new() {
      this.axios_conf
      let data = {manager: this.manager.personnel.id, branch: this.manager.branch.id}
      // console.log(this.zone.circle)
      await axios.post('personnel/managerviews/', data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.dialog = false
            this.$emit('form_status', true)
            this.$emit('snackbar_st', `New Manager Added`)
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                personnel_no: error.response.data.errors.manager,
                name: error.response.data.errors.name,
                father_name: error.response.data.errors.father_name,
                email: error.response.data.errors.email,
                mobile_no: error.response.data.errors.mobile_no,
                branch: error.response.data.errors.branch,
              });
            }
          })
      this.submit_loading = false
    },
    async edit() {
      this.axios_conf
      let data = {manager: this.manager.personnel.id, branch: this.manager.branch.id}
      // if (this.branch.zone.id) {
      //   data.zone = this.branch.zone.id
      // }
      await axios.patch(`personnel/managerviews/${this.manager.id}/update`, data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$emit('form_status', true)
            this.$emit('snackbar_st', `Manager Edited`)
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                personnel_no: error.response.data.errors.manager,
                name: error.response.data.errors.name,
                father_name: error.response.data.errors.father_name,
                email: error.response.data.errors.email,
                mobile_no: error.response.data.errors.mobile_no,
                branch: error.response.data.errors.branch,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new()
      } else if (this.action === 'edit') {
        this.edit()
      }
    },
    async get_lists() {
      this.axios_conf
      this.submit_loading = true
      this.personnel_loading = true
      this.branch_loading = true
      await axios.get("personnel/personnelviews/")
          .then((resp) => {
            this.personnel_list = resp.data.personnels
            this.$store.commit('setJWT', resp.data.key)
          })
      this.axios_conf
      await axios.get("user/branch_office")
          .then((resp) => {
            this.branch_list = resp.data.branches
            this.$store.commit('setJWT', resp.data.key)
          })

      this.submit_loading = false
      this.personnel_loading = false
      this.branch_loading = false
    },
    async fill_inputs() {
      // console.log(this.personnel.id)
      let person = await this.personnel_list.find(obj => obj.personnel_no === this.manager.personnel.personnel_no)
      // console.log(person)
      if (person) {
        this.manager.personnel = {...this.manager.personnel, ...person}
        // console.log(this.personnel)
      }
      let branch = this.branch_list.find(obj => obj.code === this.manager.branch.code)
      if (branch) {
        this.manager.branch = {...this.manager.branch, ...branch}
      }
      // console.log(this.personnel)
    },
    handleClose() {
      if (this.action !== 'edit') {
        this.$refs.vform.reset()
        this.$refs.vform.resetValidation()
      }
      this.dialog = false
    }
  },
  watch: {
    passed_item(val) {
      // console.log(val)
      this.manager = JSON.parse(JSON.stringify(val))
      // console.log(this.zone)
    },
    // zone(val) {
    //   console.log(val)
    // }
  },
  mounted() {
    if (this.passed_item) {
      this.manager = JSON.parse(JSON.stringify(this.passed_item))
      // console.log(this.manager)
    }
  }
}
</script>

<style scoped>
</style>