<template>
  <v-container v-if="has_view_permission">
    <v-snackbar
        :timeout="snackbar_timeout"
        v-model="show_snackbar"
        absolute
        centered
        shaped
        top
        color="success"
    >
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="show_snackbar = !show_snackbar"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="my-1 pa-3 blue accent-1">
      <v-card-title>
        <add_edit_form v-if="add_button" activator_name="Add New Manager"
                       form_title="Add New Manager"
                       activator_icon="mdi-plus-thick" action="add" @form_status="get_list"
                       big_btn="true" @snackbar_st="snackbars"></add_edit_form>
        <v-btn :loading="reload_btn" @click="get_list" class="ma-1">
          <v-icon>
            mdi-reload
          </v-icon>
          Reload Data Table
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card class="yellow lighten-2">
      <v-data-table
          :headers="column_headers"
          :items="table_items"
          sort-by="personnel.personnel_no"
          class="elevation-5 yellow lighten-2"
          :loading="table_loading"
          :search="search"
          loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-card-title
              flat
          >
            Manager List
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <template v-slot:item="{item}">
          <tr>
            <td class="text-center">
              {{ item.personnel.personnel_no }}
            </td>
            <td class="text-center">
              {{ item.personnel.name }}
            </td>

            <td class="text-center">
              {{ item.branch.code }}
            </td>
            <td class="text-center">
              {{ item.branch.name }}
            </td>
            <td class="text-center">
              <add_edit_form v-if="edit_button"
                             form_title="Edit Branch Manager" :passed_item="item"
                             activator_icon="mdi-pencil" action="edit" @form_status="get_list" @snackbar_st="snackbars"
              ></add_edit_form>
              <delete_dialog v-if="delete_button" @form_status="get_list" :items="item" icon="mdi-delete"
                             tooltip_text="Delete"></delete_dialog>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import add_edit_form from "@/components/manager_components/manager_sub_components/add_edit_form";
import delete_dialog from "@/components/manager_components/manager_sub_components/delete_dialog.vue";


export default {
  name: "ManagerListTable",
  components: {add_edit_form, delete_dialog},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('personnel.view_branchmanagers')
    },
    edit_button() {
      return this.$store.getters.permissionCheckers('personnel.change_branchmanagers')
    },
    delete_button() {
      return this.$store.getters.permissionCheckers('personnel.delete_branchmanagers')
    },
    add_button() {
      return this.$store.getters.permissionCheckers('personnel.add_branchmanagers')
    },
  },
  data: () => ({
    reload_btn: false,
    table_loading: false,
    column_headers: [
      {
        text: 'Personnel No',
        align: 'center',
        filterable: true,
        value: 'personnel.personnel_no',
      },
      {
        text: 'Manager Name',
        align: 'center',
        filterable: true,
        value: 'personnel.name',
      },
      {
        text: "Branch code",
        align: 'center',
        filterable: true,
        value: 'branch.code',
      },
      {
        text: 'Branch Name',
        align: 'center',
        filterable: true,
        value: 'branch.name',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: '',
      },
    ],
    table_items: [],
    search: '',
    show_snackbar: false,
    snackbar_text: null,
    snackbar_timeout: 2000
  }),
  methods: {
    async get_list() {
      this.axios_conf
      this.reload_btn = true
      this.table_loading = true
      await axios.get("personnel/managerviews/").then((resp) => {
        // console.log(resp)
        this.table_items = resp.data.managers
        this.$store.commit('setJWT', resp.data.key)
      })
      this.reload_btn = false
      this.table_loading = false
    },
    snackbars(value) {
      this.show_snackbar = true
      this.snackbar_text = value
    }

  },
  mounted() {
    this.get_list();
  }
}
</script>

<style scoped>
tbody tr:hover {
  background: #82B1FF !important;
}
</style>