<template>
  <v-container>
    <manager-list-table></manager-list-table>
  </v-container>
</template>

<script>
import ManagerListTable from "@/components/manager_components/ManagerListTable";

export default {
  name: "ManagersPage",
  components: {ManagerListTable}
}
</script>

<style scoped>

</style>