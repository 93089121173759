<template>
  <v-dialog v-model="dialog" max-width="30%">
    <template v-slot:activator="{ on:dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-2">
            {{ icon }}
          </v-icon>
        </template>
        <span>{{ tooltip_text }}</span>
      </v-tooltip>
    </template>
    <v-card class="warning justify-center">
      <v-card-title class="text-h5 justify-center"><span class="text-center">Are you sure you want to delete Personnel# <br/>{{
          this.items.personnel.name
        }}?</span>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog=false">Cancel</v-btn>
        <v-btn color="blue darken-1" :loading="submit_loading" text @click="delete_item">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "delete_dialog",
  props: ['items', 'icon', 'tooltip_text'],
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
  }),
  methods: {
    async delete_item() {
      this.axios_conf
      this.submit_loading = true
      await axios.delete(`personnel/managerviews/${this.items.id}/delete`)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$emit('form_status', true)
            this.$emit('snackbar_st', `Manager Deleted`)
          })
      // .catch((error) => {
      //   console.log(error)
      // })
      this.submit_loading = false
    },
  },
}
</script>

<style scoped>

</style>